import React from 'react'
import Button from 'core/elements/button'
import generateTestId from 'utils/test-helpers'
import Alert from './Alert'
import Modal from 'core/elements/modal'
import Text from 'core/elements/Text'

interface IConfirmationDialog {
  loading?: boolean
  open: boolean
  title?: string
  text?: JSX.Element | string
  error?: {
    title?: string
    message?: string
  }
  cancelText?: string
  confirmText?: string
  onCancel?: () => void
  onConfirm?: () => void
}

class ConfirmationDialog extends React.PureComponent<IConfirmationDialog> {
  handleCancel = () => {
    this.props.onCancel && this.props.onCancel()
  }

  handleConfirm = () => {
    this.props.onConfirm && this.props.onConfirm()
  }

  render() {
    const {
      open,
      error,
      title = 'Are you sure?',
      text = 'Are you sure?',
      cancelText = 'Cancel',
      confirmText = 'Confirm',
      loading,
    } = this.props

    return (
      <Modal
        panel="dialog"
        open={open}
        onClose={this.handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        title={title}
        footer={
          <>
            <Button onClick={this.handleCancel} variant="secondary">
              {cancelText}
            </Button>
            <Button onClick={this.handleConfirm} variant="primary" loading={loading} autoFocus>
              {confirmText}
            </Button>
          </>
        }
      >
        {!!error && <Alert variant="error" title={error?.title} message={error?.message} />}
        <Text variant="body1" component="div">
          {text}
        </Text>
      </Modal>
    )
  }
}

export default ConfirmationDialog
