import React from 'react'
import Button from 'core/elements/button'

interface Props {
  onRefresh: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
}

const RefreshButton = ({ onRefresh }: Props) => {
  return (
    <Button variant="secondary" icon="rotate" onClick={onRefresh}>
      Refresh
    </Button>
  )
}

export default RefreshButton
