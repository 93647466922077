import React from 'react'
import Text from 'core/elements/Text'
import Card from 'core/elements/card'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import clsx from 'clsx'
import generateTestId from 'utils/test-helpers'
import CardHeader from 'core/elements/card/CardHeader'

interface ContainerProps {
  title?: string | JSX.Element
  link?: JSX.Element
  topContent?: JSX.Element
  className?: string
  maxWidth?: number
  inputsWidth?: number
  middleHeader?: JSX.Element
}
const defaultMaxWidth = 932

export const useStyles = makeStyles<Theme, ContainerProps>((theme) => ({
  root: {
    maxWidth: ({ maxWidth = defaultMaxWidth }) => maxWidth,
  },
  inputWidth: {
    maxWidth: ({ maxWidth = defaultMaxWidth, inputsWidth = maxWidth / 2 }) => inputsWidth,
    marginBottom: theme.spacing(3),
  },
  requirementsTitle: {
    display: 'grid',
    alignItems: 'center',
    justifyContent: 'space-between',
    gridTemplateColumns: '1fr max-content',
  },
  titleRight: {
    display: 'grid',
    gridTemplateColumns: '1fr max-content',
    gridGap: theme.spacing(1),
  },
}))

export const FormFieldCard: React.FC<ContainerProps> = (props) => {
  const { title, topContent, middleHeader, link, className, children } = props
  const classes = useStyles(props)
  return (
    <Card
      className={clsx(classes.root, className)}
      title={
        (title || link) && (
          <CardHeader className={`form-field-card-requirementsTitle ${classes.requirementsTitle}`}>
            <div data-testid={generateTestId(title)} className={classes.title}>
              {!!title && <Text variant="subtitle2">{title}</Text>}
            </div>
            <div className={classes.titleRight}>
              <div>{!!middleHeader && middleHeader}</div>
              <div>{!!link && link}</div>
            </div>
          </CardHeader>
        )
      }
    >
      {topContent}
      {children}
    </Card>
  )
}
