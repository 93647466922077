import React, { FC } from 'react'
import { GridDialogProps } from 'core/elements/grid/helpers/getGridDialogButton'
import Button from '../button'
import SimpleLink from 'core/components/SimpleLink'
import DialogButton from './DialogButton'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import ExternalLink from 'core/components/ExternalLink'
import { bottomMiddle } from 'core/elements/menu/defaults'

interface Action<T> {
  label: string
  icon?: string
  link?: string
  externalLink?: string
  DialogComponent?: FC<GridDialogProps<T>>
  disabledFn?: (item: T) => boolean
  tooltipFn?: (item: T) => string | React.ReactNode
  refreshFn?: () => void
  customDialogProps?: any
  onClick?: () => void
}

interface Props<T> {
  actions: Action<T>[]
  entity: T
}

const useStyles = makeStyles<Theme>((theme) => ({
  actions: {
    display: 'inline-grid',
    gap: 8,
    gridAutoFlow: 'column',
  },
}))

export default function OverviewActions<T>({ actions, entity }: Props<T>) {
  const classes = useStyles()
  return (
    <div className={classes.actions}>
      {actions.map(
        ({
          label,
          icon,
          link,
          externalLink,
          DialogComponent,
          disabledFn,
          tooltipFn,
          refreshFn,
          customDialogProps,
          onClick,
        }) => {
          const disabled = disabledFn ? disabledFn(entity) : false
          const tooltip = tooltipFn ? tooltipFn(entity) : ''
          return externalLink ? (
            <ExternalLink url={disabled ? '' : externalLink}>
              <Button
                disabled={disabled}
                info={tooltip}
                infoPlacement={bottomMiddle.align}
                variant="secondary"
                icon={icon}
              >
                {label}
              </Button>
            </ExternalLink>
          ) : link ? (
            <SimpleLink src={disabled ? '' : link} key={label}>
              <Button
                disabled={disabled}
                info={tooltip}
                infoPlacement={bottomMiddle.align}
                variant="secondary"
                icon={icon}
              >
                {label}
              </Button>
            </SimpleLink>
          ) : DialogComponent ? (
            <DialogButton<T>
              key={label}
              label={label}
              icon={icon}
              disabled={disabled}
              DialogComponent={DialogComponent}
              refreshFn={refreshFn}
              customDialogProps={customDialogProps}
              entity={entity}
              info={tooltip}
              infoPlacement={bottomMiddle.align}
            />
          ) : (
            <Button variant="secondary" key={label} icon={icon} onClick={onClick}>
              {label}
            </Button>
          )
        },
      )}
    </div>
  )
}
