import React, { PureComponent } from 'react'
import { withRouter } from 'react-router-dom'
import store from 'app/store'
import { adjustWith, ensureFunction } from 'utils/fp'
import { clientActions } from 'core/client/clientReducers'
import CancelButton from 'core/components/buttons/CancelButton'
import NextButton from 'core/components/buttons/NextButton'
import PrevButton from 'core/components/buttons/PrevButton'
import WizardButtons from 'core/components/wizard/WizardButtons'
import WizardMeta from 'core/components/wizard/WizardMeta'
import WizardStepper from 'core/components/wizard/WizardStepper'
import PropTypes from 'prop-types'
import { concat, isNil, mergeDeepWithKey, mergeLeft, path, pipe, propEq, uniq } from 'ramda'
import WizardSummary from './WizardSummary'
import YamlTemplateParser from 'utils/YamlTemplateParser'

export const WizardContext = React.createContext({})

const deepConcatValues = (key, left, right) => {
  if (Array.isArray(left)) {
    return pipe(concat, uniq)(left, right)
  }
  if (typeof left === 'object') {
    return concat(left, right)
  }
  if (isNil(left) && isNil(right)) {
    return null
  }
  return left
}

class Wizard extends PureComponent {
  componentDidMount() {
    this.setState({
      activeStep: this.props.startingStep,
      latestFinishedStep: Math.max(this.state.latestFinishedStep, this.props.startingStep),
    })
    const { dispatch } = store
    dispatch(clientActions.setSidebarPane('custom'))
    dispatch(clientActions.setSidebarState('expanded'))
  }

  componentWillUnmount() {
    const { dispatch } = store
    dispatch(clientActions.setSidebarPane('default'))
  }

  isLastStep = () => this.state.activeStep === this.state.steps.length - 1
  isComplete = () => this.state.activeStep > this.state.steps.length - 1
  lastStep = () => this.state.steps.length - 1
  hasNext = () => this.state.activeStep < this.lastStep() && !this.props.disableNext
  hasBack = () => !this.props.hideBack && this.state.activeStep > 0
  isFinishAndReviewVisible = () =>
    this.state.activeStep < this.state.steps.length - 2 && !this.props.disableNext

  canBackAtFirstStep = () =>
    !this.props.hideBack && this.state.activeStep === 0 && !!this.props.originPath

  // Callbacks indexed by step ID to be called before navigating to the next step
  nextCb = {}

  activateStep = () => {
    // Activate the step if we don't have one already
    const { steps, activeStep, latestFinishedStep } = this.state
    if (steps[activeStep]) {
      this.setState({
        activeStepId: steps[activeStep].stepId,
        latestFinishedStep: Math.max(latestFinishedStep, activeStep),
      })
    }
  }

  setActiveStep = (stepId, stepNum) => {
    this.setState({
      activeStepId: stepId,
      activeStep: stepNum,
      latestFinishedStep: Math.max(this.state.latestFinishedStep, stepNum),
    })
  }

  getActiveStepId = (steps, activeStep) =>
    steps[activeStep]
      ? {
          activeStepId: steps[activeStep].stepId,
          latestFinishedStep: Math.max(this.state.latestFinishedStep, activeStep),
        }
      : {}

  addStep = (newStep) => {
    this.setState((state) => {
      const { steps: prevSteps, activeStep } = state
      const steps = [...prevSteps, newStep]
      const { activeStepId, latestFinishedStep } = this.getActiveStepId(steps, activeStep)
      return {
        activeStepId,
        latestFinishedStep,
        steps,
      }
    })
  }

  handleOriginBack = () => {
    const { history, originPath } = this.props
    history.push(originPath)
  }

  handleBack = () => {
    this.setState((state) => ({
      activeStep: state.activeStep - 1,
      ...this.getActiveStepId(state.steps, state.activeStep - 1),
    }))
  }

  onNext = (cb) => {
    this.nextCb[this.state.activeStep] = cb
  }

  handleNext = async () => {
    const { onComplete, singleStep } = this.props
    const { steps, activeStep, wizardContext } = this.state
    // This is triggerSubmit in the ValidatedForm
    if (this.nextCb[activeStep]) {
      const ok = await this.nextCb[activeStep]()
      if (!ok) {
        return
      }
    }

    if (singleStep) {
      onComplete(
        this.state.wizardContext,
        this.state.getParsedYamls('json'), // format :json
        // this.state.getParsedYamls(), // format:yaml
      )
      return
    }

    this.setState(
      (state) => ({
        activeStep: state.activeStep + 1,
        ...this.getActiveStepId(state.steps, state.activeStep + 1),
      }),
      () => {
        if (steps[activeStep] && steps[activeStep].onNext) {
          steps[activeStep].onNext(wizardContext)
        }
        if (this.isComplete()) {
          onComplete(
            this.state.wizardContext,
            this.state.getParsedYamls('json'), // format:json
            // this.state.getParsedYamls(), // format:yaml
          )
        }
      },
    )
  }

  onFinishAndReview = () => {
    const { onComplete } = this.props
    const { activeStep } = this.state

    if (this.nextCb[activeStep] && this.nextCb[activeStep]() === false) {
      return
    }

    this.setState(
      (state) => ({
        activeStep: state.steps.length - 1,
        ...this.getActiveStepId(state.steps, state.steps.length - 1),
      }),
      () => {
        if (this.isComplete()) {
          onComplete(
            this.state.wizardContext,
            this.state.getParsedYamls('json'), // format :json
            // this.state.getParsedYamls(), // format:yaml
          )
        }
      },
    )
  }

  setWizardSummaryProps = (fields) => {
    this.setState((state) => ({
      summary: mergeDeepWithKey(deepConcatValues, fields, state.summary),
    }))
  }
  updateYamlTemplates = (activeStepId, yamlTemplates = []) => {
    const { steps } = this.state
    if (steps.find(propEq('stepId', activeStepId))) {
      this.setState({
        steps: adjustWith(propEq('stepId', activeStepId), mergeLeft({ yamlTemplates }), steps),
      })
    }
  }

  getParsedYamls = (format = 'yaml') => {
    const parsedSchemas = this.state.steps
      .map(({ yamlTemplates = [] }) =>
        yamlTemplates.map((yamlTemplate) => {
          const { schema, hide, setValues } = yamlTemplate
          const values = setValues ? setValues(this.state.wizardContext) : this.state.wizardContext
          if (hide && hide(values)) return null
          const templateParser = new YamlTemplateParser(schema || {})
          templateParser.setValues(values)
          return format === 'yaml' ? templateParser.toString() : templateParser.getJson()
        }),
      )
      .flat()
      .filter(Boolean)

    return [
      ...new Map(
        parsedSchemas.map((item) => [
          `${path(['metadata', 'name'], item)}-${path(['kind'], item)}`,
          item,
        ]),
      ).values(), // removing duplicates by name-kind
    ]
  }

  setWizardContext = (newValues, cb) => {
    this.setState(
      (state) => ({ wizardContext: { ...state.wizardContext, ...newValues } }),
      () => {
        if (cb) {
          cb(this.state.wizardContext)
        }
      },
    )
  }

  state = {
    handleBack: this.handleBack,
    handleNext: this.handleNext,
    addStep: this.addStep,
    addYamlTemplates: this.addYamlTemplates,
    updateYamlTemplates: this.updateYamlTemplates,
    getParsedYamls: this.getParsedYamls,
    activeStep: this.props.startingStep || 0,
    latestFinishedStep: this.props.startingStep || 0,
    setActiveStep: this.setActiveStep,
    steps: [],
    summary: {},
    activeStepId: null,
    wizardContext: this.props.context || {},
    setWizardContext: this.setWizardContext,
    setWizardSummaryProps: this.setWizardSummaryProps,
  }

  render() {
    const { wizardContext, setWizardContext, steps, activeStep, latestFinishedStep } = this.state
    const {
      showSteps,
      children,
      submitLabel,
      finishAndReviewLabel,
      onCancel,
      showFinishAndReviewButton,
      hideAllButtons,
      additionalActions,
      customNext,
      error,
      errors,
    } = this.props
    const shouldShowFinishAndReview =
      typeof showFinishAndReviewButton === 'function'
        ? showFinishAndReviewButton(wizardContext)
        : showFinishAndReviewButton
    const shouldHideButtons =
      typeof hideAllButtons === 'function'
        ? hideAllButtons(wizardContext, activeStep)
        : hideAllButtons
    const renderStepsContent = ensureFunction(children)
    const iconRenderer = ensureFunction(this.state.summary?.icon)
    const currentStep = steps[activeStep]
    const nextBtnDisabled =
      currentStep && currentStep.validateFields && !currentStep.validateFields(wizardContext)
    return (
      <WizardContext.Provider value={this.state}>
        <WizardMeta>
          {showSteps && steps.length > 1 && (
            <WizardStepper
              steps={steps}
              activeStep={activeStep}
              latestFinishedStep={latestFinishedStep}
              setActiveStep={this.setActiveStep}
            />
          )}
        </WizardMeta>
        <WizardSummary
          {...this.state.summary}
          error={error}
          errors={errors}
          icon={iconRenderer(wizardContext)}
          currentStep={currentStep}
          steps={steps}
          wizardContext={wizardContext}
          sidebarActions={
            shouldShowFinishAndReview &&
            this.isFinishAndReviewVisible() && (
              <NextButton onClick={this.onFinishAndReview} showForward={false}>
                {finishAndReviewLabel}
              </NextButton>
            )
          }
          footer={
            <WizardButtons>
              {!shouldHideButtons && (
                <>
                  {onCancel && <CancelButton onClick={onCancel} />}
                  {this.hasBack() && <PrevButton onClick={this.handleBack} />}
                  {this.canBackAtFirstStep() && <PrevButton onClick={this.handleOriginBack} />}
                  {!customNext && this.hasNext() && (
                    <NextButton disabled={nextBtnDisabled} onClick={this.handleNext}>
                      Next
                    </NextButton>
                  )}
                  {customNext && this.hasNext() && (
                    <>{customNext(this.handleNext, wizardContext, activeStep)}</>
                  )}
                  {this.isLastStep() && (
                    <NextButton onClick={this.handleNext} showForward={false}>
                      {submitLabel}
                    </NextButton>
                  )}
                </>
              )}
              {/* todo refactor this to be an object instead of params */}
              {!!additionalActions && (
                <>{additionalActions(wizardContext, activeStep, this.handleNext)}</>
              )}
            </WizardButtons>
          }
        >
          {renderStepsContent({
            wizardContext,
            setWizardContext,
            onNext: this.onNext,
            handleNext: this.handleNext,
            handleBack: this.handleBack,
            setActiveStep: this.setActiveStep,
          })}
        </WizardSummary>
      </WizardContext.Provider>
    )
  }
}

Wizard.propTypes = {
  originPath: PropTypes.string,
  showSteps: PropTypes.bool,
  onComplete: PropTypes.func,
  onCancel: PropTypes.func,
  context: PropTypes.object,
  submitLabel: PropTypes.string,
  showFinishAndReviewButton: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  finishAndReviewLabel: PropTypes.string,
  disableNext: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.func]).isRequired,
  startingStep: PropTypes.number,
  hideBack: PropTypes.bool,
  hideAllButtons: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  singleStep: PropTypes.bool,
  customNext: PropTypes.func,
  additionalActions: PropTypes.func,
}

Wizard.defaultProps = {
  showSteps: true,
  submitLabel: 'Complete',
  finishAndReviewLabel: 'Finish and Review',
  onComplete: (value) => {
    console.info('Wizard#onComplete handler not implemented.  Falling back to console.log')
    console.log(value)
  },
  startingStep: 0,
  hideBack: false,
  hideAllButtons: false,
  singleStep: false,
}

export default withRouter(Wizard)
