import React, { useEffect, useState, useContext, PropsWithChildren } from 'react'
import { WizardContext } from 'core/components/wizard/Wizard'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import useWizardSummaryUpdater from 'core/components/wizard/useWizardSummaryUpdater'
import Theme from 'core/themes/model'
import { YamlTemplate } from './YamlTemplates'

interface Props {
  label?: string
  stepId: string
  onNext?: any
  keepContentMounted?: boolean
  validateFields?: any
  className?: string
  showSummaryLabels?: boolean
  summaryFields?: any
  summaryCalloutFields?: any
  summaryKeyOverrides?: any
  summaryRenderLabels?: any
  summaryShowUndefinedFields?: any
  summaryTitle?: any
  summaryIcon?: any
  yamlTemplates?: YamlTemplate[]
  showAllYamls?: boolean
  collapseYamls?: boolean
  showSummaryYamls?: boolean
}

export default function WizardStep({
  label,
  stepId,
  onNext,
  children,
  keepContentMounted = false,
  validateFields,
  className,
  showSummaryLabels,
  summaryFields,
  summaryCalloutFields,
  summaryKeyOverrides,
  summaryRenderLabels,
  summaryShowUndefinedFields,
  summaryTitle,
  summaryIcon,
  showSummaryYamls,
  yamlTemplates,
  showAllYamls,
  collapseYamls,
}: PropsWithChildren<Props>) {
  const { activeStepId, addStep, updateYamlTemplates } = useContext(WizardContext as any)

  const classes = useWizardStepStyles({ stepId, activeStepId })
  const [rendered, setIsRendered] = useState(false)

  useWizardSummaryUpdater({
    stepId,
    fields: summaryFields,
    calloutFields: summaryCalloutFields,
    keyOverrides: summaryKeyOverrides,
    renderLabels: summaryRenderLabels,
    showUndefinedFields: summaryShowUndefinedFields,
    title: summaryTitle,
    icon: summaryIcon,
    showSummaryLabels,
    showSummaryYamls,
  })

  useEffect(() => {
    addStep({ stepId, label, onNext, validateFields, yamlTemplates, showAllYamls, collapseYamls })
  }, [])

  useEffect(() => {
    updateYamlTemplates(stepId, yamlTemplates)
  }, [yamlTemplates])

  useEffect(() => {
    // After we render the step contents for the first time we will keep it mounted
    // (but hidden) to have it quickly available when navigating back and forth
    if (!rendered && activeStepId === stepId) {
      setIsRendered(true)
    }
  }, [activeStepId])

  return activeStepId === stepId || (rendered && keepContentMounted) ? (
    <div className={clsx(classes.root, className)}>{children}</div>
  ) : null
}

interface StyleProps {
  stepId: string
  activeStepId: string
}
const useWizardStepStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: {
    display: ({ stepId, activeStepId }) =>
      // Added !important to make sure not even custom styles
      // will override the property when the step is not active
      stepId === activeStepId ? 'block' : 'none !important',
  },
}))
