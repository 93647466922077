import { AppPlugins, pluginRoutePrefix } from 'app/constants'
import {
  IVirtualMachineDetailsPageTabs,
  VirtualMachineCreateTypes,
  VirtualMachineResourceTypes,
} from 'app/plugins/kubevirt/components/virtual-machines/model'
import { IVirtualMachineInstanceDetailsPageTabs } from 'app/plugins/kubevirt/components/virtual-machines/vmi-model'
import { Route } from 'core/plugins/route'

const metadata = {
  showGlobalFilters: true,
}

export const kubevirtRoutes = {
  kubevirt: {
    overview: Route.register({
      url: `${pluginRoutePrefix}/overview`,
      id: 'vmsOverview',
      metadata,
      defaultParams: {
        plugin: AppPlugins.KubeVirt,
      },
      name: 'VirtualMachines:Overview',
    }),
  },
  virtualMachineInstances: {
    details: Route.register<{
      clusterId: string
      id: string
      tab?: IVirtualMachineInstanceDetailsPageTabs
    }>({
      id: 'vmiDetails',
      url: `${pluginRoutePrefix}/clusters/:clusterId/virtual-machine-instances/:id/:tab`,
      breadcrumbs: new Map([
        ['workloads', null],
        ['virtual machines', 'vmsOverview'],
        [':id', 'vmiDetails'],
      ]),
      metadata,
      defaultParams: {
        plugin: AppPlugins.KubeVirt,
        tab: 'overview',
      },
      name: 'VirtualMachineInstances:Details',
      tab: 'tab',
    }),
    add: Route.register({
      url: `${pluginRoutePrefix}/virtual-machines/add/new`,
      metadata,
      defaultParams: {
        plugin: AppPlugins.KubeVirt,
        createType: VirtualMachineResourceTypes.VirtualMachineInstance,
      },
      name: 'VirtualMachineInstances:Add:NewVM',
    }),
    import: {
      url: Route.register({
        url: `${pluginRoutePrefix}/virtual-machines/import/url`,
        metadata,
        defaultParams: {
          plugin: AppPlugins.KubeVirt,
          createType: VirtualMachineCreateTypes.ImportURL,
        },
        name: 'VirtualMachineInstances:Import:URL',
      }),
      disk: Route.register({
        url: `${pluginRoutePrefix}/virtual-machines/import/disk`,
        metadata,
        defaultParams: {
          plugin: AppPlugins.KubeVirt,
          createType: VirtualMachineCreateTypes.ImportDisk,
        },
        name: 'VirtualMachineInstances:Import:Disk',
      }),
    },
    clone: {
      pvc: Route.register({
        url: `${pluginRoutePrefix}/virtual-machines/clone/pvc`,
        metadata,
        defaultParams: {
          plugin: AppPlugins.KubeVirt,
          createType: VirtualMachineCreateTypes.ClonePVC,
        },
        name: 'VirtualMachineInstances:Clone:PVC',
      }),
    },
  },
  // These routes will be changed when adding vm/vmi details, for now just
  // need them not to conflict with above routes
  virtualMachines: {
    details: Route.register<{
      clusterId: string
      id: string
      tab?: IVirtualMachineDetailsPageTabs
    }>({
      id: 'vmDetails',
      url: `${pluginRoutePrefix}/clusters/:clusterId/virtual-machines/:id/:tab`,
      breadcrumbs: new Map([
        ['workloads', null],
        ['virtual machines', 'vmsOverview'],
        [':id', 'vmDetails'],
      ]),
      metadata,
      defaultParams: {
        plugin: AppPlugins.KubeVirt,
        tab: 'overview',
      },
      name: 'VirtualMachines:Details',
      tab: 'tab',
    }),
    add: Route.register({
      url: `${pluginRoutePrefix}/virtual-machines/add/new`,
      metadata,
      defaultParams: {
        plugin: AppPlugins.KubeVirt,
        createType: VirtualMachineCreateTypes.AddNew,
      },
      name: 'VirtualMachines:Add:NewVM',
    }),
    edit: Route.register({
      url: `${pluginRoutePrefix}/virtual-machines/clusters/:clusterId/vms/edit/:id`,
      metadata,
      defaultParams: {
        plugin: AppPlugins.KubeVirt,
      },
      name: 'VirtualMachines:VM:Edit',
    }),
  },
}
