import withFormContext, {
  ValidatedFormInputPropTypes,
} from 'core/components/validatedForm/withFormContext'
import Input from 'core/elements/input/Input'
import PropTypes from 'prop-types'
import React, { useCallback } from 'react'

const TextField = ({
  value,
  label,
  hasError,
  errorMessage,
  required,
  variant,
  onChange,
  type,
  returnAsString,
  getCurrentValue,
  updateFieldValue,
  setFieldValue,
  ...restProps
}) => {
  const handleChange = useCallback(
    (e) => {
      // HTML specs says that <input type="number"> return strings but it's more useful if we
      // convert it to a `Number` to reduce type casting all over the place.
      const strVal = e.target.value
      const value =
        type && type.toLowerCase() === 'number' && strVal !== '' ? Number(strVal) : strVal
      const returnValue = returnAsString ? String(value) : value

      if (onChange) {
        onChange(returnValue)
      }
    },
    [onChange, type],
  )
  return (
    <Input
      {...restProps}
      label={label && required ? `${label} *` : label}
      variant={variant}
      error={errorMessage}
      value={value !== undefined ? (type === 'number' ? Number(value) : value) : ''}
      onChange={handleChange}
      type={type}
      // helperText={errorMessage}
    />
  )
}

TextField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  returnAsString: PropTypes.bool,
  ...ValidatedFormInputPropTypes,
}

export default withFormContext(TextField)
