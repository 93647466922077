import React from 'react'
import Checkbox from 'core/elements/input/Checkbox'
import { SelectableParsedGridRow } from './hooks/useGridSelectableRows'
import Radio from 'core/elements/input/Radio'
import { noop } from 'utils/fp'
import { memoizeShallow } from 'utils/misc'
import generateTestId from 'utils/test-helpers'

interface SelectRowColumnProps {
  className: string
  multiSelection: boolean
  isSelectable: boolean
  isSelected: boolean
}

const SelectRowColumn = memoizeShallow(
  function SelectRowColumn({
    className,
    multiSelection,
    isSelectable,
    isSelected,
  }: SelectRowColumnProps) {
    if (isSelectable === undefined) {
      return null
    }
    const Toggler = multiSelection ? Checkbox : Radio
    return (
      <td data-testid={generateTestId('cluster', 'checkbox', 'selection')} className={className}>
        <Toggler disabled={!isSelectable} checked={isSelected} onChange={noop} />
      </td>
    )
  },
  {
    maxSize: 8,
  },
)

export type GridRowProps<T> = SelectableParsedGridRow<T> & {
  className: string
  tdClassName: string
  cellClassName: string
}

export default memoizeShallow(
  function GridRow<T>(props: GridRowProps<T>) {
    const {
      isSelectable,
      multiSelection,
      isSelected,
      toggleSelect,
      getCells,
      item,
      className,
      tdClassName,
      cellClassName,
    } = props
    return (
      <tr className={className} onClick={toggleSelect}>
        <SelectRowColumn
          className={tdClassName}
          {...{
            multiSelection,
            isSelectable,
            isSelected,
          }}
        />
        {getCells().map(({ key, CellComponent, value, getFormattedValue }, idx) => {
          const formattedValue = getFormattedValue()
          return (
            <td data-testid={generateTestId(key)} key={key} className={tdClassName}>
              <CellComponent
                index={idx}
                item={item}
                value={value}
                title={String(formattedValue)}
                className={cellClassName}
              >
                {formattedValue}
              </CellComponent>
            </td>
          )
        })}
      </tr>
    )
  },
  {
    maxSize: 100000,
  },
)
