import { UseParamsReturnType } from 'core/hooks/useParams'
import { useMemo } from 'react'
import useScopedPreferences from 'core/session/useScopedPreferences'
import { generateObjMemoizer } from 'utils/misc'

export type GlobalParams = {
  clusterId?: string[]
  namespace?: string[]
}

export default function useGlobalParams<P>(
  useParamsHook: (defaultParams?: P) => UseParamsReturnType<P>,
  defaultParams: P,
): UseParamsReturnType<P> & { allParams: P & GlobalParams; globalParams: GlobalParams } {
  const memoizedParams = useMemo(() => generateObjMemoizer<P & GlobalParams>(), [])
  const prefParams = useParamsHook(defaultParams)
  const { params } = prefParams
  const { prefs: globalParams } = useScopedPreferences<GlobalParams>('globalParams')
  const allParams = memoizedParams({ ...params, ...globalParams } as P & GlobalParams)
  return { ...prefParams, allParams, globalParams }
}
