import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import AutocompleteBase from 'core/components/AutocompleteBase'
import uuid from 'uuid'
import { assoc, differenceWith, omit } from 'ramda'
import { makeStyles } from '@material-ui/styles'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import Text from 'core/elements/Text'
import withTooltip from 'core/elements/tooltip/withTooltip'

const useKeyValueStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: ({ showDeleteButton }) =>
      showDeleteButton ? '172px 6px 172px 32px' : '185px 6px 185px',
    gridTemplateRows: '55px',
    gridGap: '12px',
    justifyItems: 'start',
    alignItems: 'center',
  },
  autocomplete: {
    width: '100%',

    '& .MuiFormControl-root': {
      marginTop: 0,
      marginBottom: 0,
      width: '100%',
    },
  },
  minus: {
    fontWeight: 900,
    color: theme.palette.blue.main,
    justifySelf: 'end',
  },
  additionalFields: {
    margin: theme.spacing(2, 0),
    width: '100%',
  },
}))

const getAddtionalFieldValues = (additionalFields, entry) =>
  additionalFields.reduce((accum, { id }) => ({ ...accum, [id]: entry[id] || '' }), {})

const KeyValue = ({
  entry = {},
  onChange,
  onDelete,
  keySuggestions = [],
  valueSuggestions = [],
  keyLabel = 'Key',
  valueLabel = 'Value',
  showDeleteButton = true,
  additionalFields = [],
}) => {
  const classes = useKeyValueStyles({ showDeleteButton })
  const [state, setState] = useState({
    id: entry.id || uuid.v4(),
    key: entry.key || '',
    value: entry.value || '',
    ...getAddtionalFieldValues(additionalFields, entry),
  })

  useEffect(() => {
    onChange(state)
  }, [state])

  const handleChange = (field) => (value) => setState(assoc(field, value, state))

  return (
    <>
      <div className={classes.root}>
        <AutocompleteBase
          inputProps={{ size: 14 }}
          fullWidth
          label={keyLabel}
          value={state.key}
          onChange={handleChange('key')}
          suggestions={keySuggestions}
          className={classes.autocomplete}
        />
        <Text variant="body2">-</Text>
        <AutocompleteBase
          inputProps={{ size: 14 }}
          fullWidth
          label={valueLabel}
          value={state.value}
          onChange={handleChange('value')}
          suggestions={valueSuggestions}
          className={classes.autocomplete}
        />
        {showDeleteButton && (
          <FontAwesomeIcon className={classes.minus} onClick={onDelete}>
            minus-circle
          </FontAwesomeIcon>
        )}
      </div>
      {additionalFields.map(({ id, label, Component, props }) => (
        <div key={id} className={classes.additionalFields}>
          <Component
            id={id}
            label={label}
            value={entry[id]}
            onChange={handleChange(id)}
            {...props}
          />
        </div>
      ))}
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'flex-start',
    maxWidth: '100%',
  },
  addNewEntryContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, max-content)',
    marginTop: theme.spacing(2),
    alignItems: 'center',
  },
  plus: {
    color: theme.palette.primary.main,
    marginRight: theme.spacing(2),
    fontWeight: 900,
  },
}))

const newEntry = () => ({ id: uuid.v4(), key: '', value: '' })

const initialEntry = newEntry()

// Unfortunately React forces us to use `key` for each item in an
// array and we can't use the index because that will break
// functionality if we delete anything in the middle of the array.
// This forces us to inject an id field into every entry and then
// filter it out before submitting. :(
const addId = (entry) => ({ ...entry, id: uuid.v4() })

const cmp = (x, y) => x.key === y.key && x.value === y.value && x.id === y.id
const getInitialEntries = (currEntries = [], newEntries = []) =>
  differenceWith(cmp, newEntries, currEntries)

const KeyValues = ({
  entries: incomingEntries,
  onChange,
  keySuggestions = undefined,
  valueSuggestions = undefined,
  blacklistedTags = [],
  addLabel = 'Add',
  keyLabel = 'Key',
  valueLabel = 'Value',
  allowMultipleValues = true,
  additionalFields = [],
}) => {
  const classes = useStyles()
  const [entries, setEntries] = useState([initialEntry])
  const [loadedInitialEntries, setLoadedInitialEntries] = useState(false)

  const addBlankEntry = () => setEntries([...entries, newEntry()])
  const deleteEntry = (id) => () => setEntries(entries.filter((x) => x.id !== id))
  const handleChange = (entry) => setEntries(entries.map((x) => (x.id === entry.id ? entry : x)))

  useEffect(() => {
    // Load initial entries
    if (loadedInitialEntries || incomingEntries === undefined) return
    const initialEntries = getInitialEntries(entries, incomingEntries)
    setLoadedInitialEntries(true)
    if (initialEntries.length === 0) return
    allowMultipleValues
      ? setEntries([...incomingEntries, ...entries])
      : setEntries([...incomingEntries])
  }, [incomingEntries, entries, loadedInitialEntries])

  useEffect(() => {
    // Remove empty entries and strip out id
    const noEmptyKeys = (x) => x.key.length > 0
    const removeId = (entry) => omit(['id'], entry)
    const sanitized = (entries || []).filter(noEmptyKeys).map(removeId)
    onChange && onChange(sanitized)
  }, [entries])

  const filteredEntries = entries.filter((entry) => !blacklistedTags.includes(entry.key))

  return (
    <div className={classes.root}>
      {filteredEntries.map((entry) => (
        <KeyValue
          key={entry.id}
          keySuggestions={keySuggestions}
          valueSuggestions={valueSuggestions}
          entry={entry}
          onChange={handleChange}
          onDelete={deleteEntry(entry.id)}
          keyLabel={keyLabel}
          valueLabel={valueLabel}
          showDeleteButton={allowMultipleValues}
          additionalFields={additionalFields}
        />
      ))}
      {allowMultipleValues && (
        <div className={classes.addNewEntryContainer}>
          <FontAwesomeIcon className={classes.plus} onClick={addBlankEntry} size="lg">
            plus-circle
          </FontAwesomeIcon>
          <Text variant="body2">{addLabel}</Text>
        </div>
      )}
    </div>
  )
}

export const EntryShape = PropTypes.shape({
  key: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
})

KeyValues.propTypes = {
  keySuggestions: PropTypes.arrayOf(PropTypes.string),
  valueSuggestions: PropTypes.arrayOf(PropTypes.string),
  entries: PropTypes.arrayOf(EntryShape),
  onChange: PropTypes.func,
  blacklistedTags: PropTypes.arrayOf(PropTypes.string),
}

export default withTooltip(KeyValues)
